import { isValidPhoneNumber } from "react-phone-number-input";
import { customerActions } from "../../../_actions";
import { AppDispatch } from "../../../_store";
import { DataType, FieldData, ModalFrameworkBase, ValidationError, ValueProps } from "./Framework/ModalTypes";
import i18next from "i18next";
import { tenantService } from "../../../_services";
import { store } from "../../../_store";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

type CreateCustomerData = {
    name: string,
    email?: string,
    phone?: string,
    isPrivate?: boolean,
    salesAssociateId?: number
}

export class CustomerModal extends ModalFrameworkBase<CreateCustomerData> {
    title?: string = i18next.t("common:page.title.customer")
    data: CreateCustomerData = { name: '', email: undefined, phone: undefined, }
    fields: FieldData<CreateCustomerData>[];

    constructor(updated: () => void) {
        super(updated);
        this.fields = [
            { name: 'name', important: true, dataType: DataType.string, change: (value: any) => { this.update('name', value); } },
            { name: 'email', dataType: DataType.string, change: (value: any) => { this.update('email', value); } },
            {
                name: 'phone', element: (props: ValueProps) => <PhoneInput
                    international={true}
                    withCountryCallingCode={true}
                    placeholder="Phone Number"
                    value={props.value}
                    onChange={(v) => this.update('phone', v) }
                />
            },
        ]
    }

    async init() {
        const user = store.getState().authentication.user;
        const tenant = await tenantService.getTenantById(user.activeTenant.id, ['options']);
        if (tenant.options.allowPrivateCustomers) {
            this.data = ({ ...this.data, isPrivate: true, salesAssociateId: user.id });
        }
        return this;
    }

    save(dispatch: AppDispatch): void {
        dispatch(customerActions.add(this.data));
    }

    validate(save: boolean): Promise<ValidationError<CreateCustomerData>[]> {
        const errors: ValidationError<CreateCustomerData>[] = [];
        if (!this.data.name || this.data.name === '') {
            errors.push({ fieldName: 'name', error: i18next.t("common:modals.name-is-empty") })
        }

        //If save check for undefined, otherwise if both are undefined don't check
        if (save && !this.data.email && !this.data.phone) {
            errors.push({ fieldName: 'phone', error: i18next.t("common:modals.please-enter-a-valid-phone-number-or-email-address") })
        } else {
            const emailOk = this.data.email && this.data.email !== '';
            const phoneOk = this.data.phone && isValidPhoneNumber(this.data.phone);
            console.log(save, emailOk, phoneOk, this.data);
            if (!emailOk && !phoneOk) {
                errors.push({ fieldName: 'phone', error: i18next.t("common:modals.please-enter-a-valid-phone-number-or-email-address") })
            }
        }


        return Promise.resolve(errors);
    }
}