import { useTranslation } from 'react-i18next';
import { NavBarToggle } from './NavBarToggle';
import { Dropdown } from 'react-bootstrap'
import { config } from '../../_constants';
import { useEffect } from 'react';

export const languageList = [
    { name: "English", shortName: 'EN', value: "en-GB", flag: "fi fi-gb" },
    { name: "English", shortName: 'EN', value: "en-US", flag: "fi fi-us" },
    { name: "Español", shortName: 'ES', value: "es-ES", flag: "fi fi-es" },
    { name: "தமிழ்", shortName: 'இல்', value: "ta-IN", flag: "fi fi-in" }
]

export function LanguageSelector(props: { className: string }): JSX.Element {

    useEffect(() => {
        if (config.environment == 'DEV' || config.environment == 'LOCAL') {
            if (!languageList.some(l => l.name == 'Test'))
                languageList.push(({ name: 'Test', shortName: 'DEV', value: 'cimode', flag: "fi fi-xx" }))
        }
        const lang = localStorage.getItem("language");
        if (lang) {
            setLangName(lang)
        }
    }, [])

    const { i18n } = useTranslation("common");
    function getFlag(): string {
        const lang = languageList.filter(({ value }) => value === i18n.language);
        return lang && lang[0] ? lang[0].flag : '?'
    }

    function getLangName(): string {
        const lang = languageList.filter(({ value }) => value.toLowerCase() === i18n.language.toLowerCase());
        return lang && lang[0] ? lang[0].shortName : '?'
    }

    function setLangName(value: string) {
        localStorage.setItem("language", value)
        i18n.changeLanguage(value)
    }

    return <Dropdown className={"language-handler nav-item " + props.className}>
        <Dropdown.Toggle as={NavBarToggle} className={"not-apply"} href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
            <span className='toggle flag'><i className={getFlag()} /></span> <span className='initials'>{getLangName()}</span> <i className='fa fa-chevron-down' />
        </Dropdown.Toggle>
        <Dropdown.Menu align={'end'} className="notifications" aria-labelledby="navbarDropdown">
            {languageList.map(({ name, value, flag }) => <li className='language-option' key={value} onClick={() => setLangName(value)}>{flag && <span className='flag'><i className={flag} /></span>}<span className='language-name'>{name}</span></li>)}
        </Dropdown.Menu>
    </Dropdown>
}