import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { orderService } from '../../../_services';
import { apiService } from '../../../_services/api.service'
import '../../../Styles/dashboard.scss';
import { formatDate, formatPrice } from '../../../_helpers/utility';
import { OrderStatus } from '../../../_constants/orders.constants';
import { AssetComponent } from '../../../Components/Layout/AssetComponent';
import { useTranslation } from 'react-i18next';


export default function JourneyPendingOrders() {
    const [view, setView] = useState('pending');
    const { t } = useTranslation("common");
    const [orders, setOrders] = useState([]);
    const [customers, setCustomers] = useState([]);
    const currentUserId = useSelector(state => state.authentication.user.id);

    async function getOrders() {
        const options = { searchFields: ['sellerid', 'deliveryAddressId', 'payment', 'order'], searchValues: [currentUserId, null, OrderStatus.Requested], sortBy: 'created', sortDirection: 'descending', pageSize: 4, include: ['customer', 'deliveryAddress'], }
        const orders = await orderService.searchOrders(options)

        setOrders(orders.items);
    }

    async function getCustomers() {
        try {
            const today = new Date();
            const options = {
                searchFields: [
                    'salesAssociateId',
                    'MONTH(dateOfBirth)=',
                    'DAY(dateOfBirth)=',
                ],
                searchValues: [
                    currentUserId,
                    today.getMonth() + 1,
                    today.getDate()
                ]
            }
            const customers = await apiService.getItems('customers', options)
            console.log("API Response for Customers:", customers);

            /* Remove below code once API handles the Date of birth search */
            const items = customers.items.filter(({ dateOfBirth }) => {
                const dob = new Date(dateOfBirth);
                if (dob.getMonth() === today.getMonth() && dob.getDate() === today.getDate()) {
                    return true
                }
                false
            });
            /* */

            setCustomers(items);
        } catch (error) {
            console.error("Error fetching customers:", error)
        }
    }

    useEffect(() => {
        getOrders();
        getCustomers();
    }, [])

    function retrieveOrders(orders) {
        if (!orders || !orders.length) return <div>No Pending Orders</div>;
        return orders.map((o) => {
            return (
                <a href={`orders/${o.id}`} key={`order-${o.id}`} className='customer-pending-order'>
                    <span className='customer-pending-order-title'>{t("dashboard.pending-orders")} - {t("dashboard.missing-customer-address")} </span>
                    <div className='customer-pending-order-table'>
                        {/* <div className='customer-order-name'><label>{o.customer ? o.customer.name : t('dashboard.guest')}</label></div> */}
                        <div className='customer-order-name'><span>{t("dashboard.customer")}</span><label>{o.order ? o.customer.name : t('dashboard.guest')}</label></div>
                        <div><span>{t("dashboard.date")}</span>{o.created ? formatDate(o.created) : undefined}</div>
                        <div><span>{t("dashboard.order")}</span> <label>#{o.id} </label></div>
                        <div className='total-order-price'><span>{t("dashboard.price")}</span><label>{formatPrice(o.total, o.currency)}</label></div>
                    </div>
                </a>
            )

        })

    }

    function retrieveMyCustomers() {
        if (!customers || customers.length === 0) {
            return <div>{t("dashboard.no-customers-found")}</div>;
        }
        return customers.map(customer => (
            <div key={`customer-${customer.id}`} className='customer-info'>
                {customer?.assetId ?
                    <AssetComponent assetId={customer?.assetId} className="" height={50} width={50} />
                    :
                    <span className='customer-info-icon'><i className="fas fa-user-alt"></i></span>}
                <div className='customer-info-wrap'>
                    <span className='customer-info-name'>{customer.name}</span>
                    <span className='customer-info-occasion'>{formatDate(customer.dateOfBirth)}</span>
                    <span className='customer-info-action'><i className="fas fa-paper-plane"></i>{t("dashboard.birthday-wish")}</span>
                </div>
                {/* <span className=''>{formatDate(customer.dateOfBirth)}</span>
                <span className=''>{formatDate(customer.created)}</span> */}
            </div>
        ));
    }

    return (
        <div className='card-holder col-12 h-100'>
            <Card className='dashboard-pending-orders-card h-100'>
                <div className='dashboard-card-sm-title'>{t("dashboard.actions-engagement")}</div>
                <div className='dashboard-card-tags-wrap'>
                    <div className={`dashboard-card-tag ${view === 'pending' ? 'active' : ''}`} onClick={() => setView('pending')}>
                        {t("dashboard.pending")}
                    </div>
                    <div className={`dashboard-card-tag ${view === 'occasions' ? 'active' : ''}`} onClick={() => setView('occasions')}>
                        {t("dashboard.occasions")}
                    </div>
                </div>
                <Card.Body>
                    {view === 'pending' ? (
                        retrieveOrders(orders)
                    ) : (
                        retrieveMyCustomers()
                    )}
                </Card.Body>
            </Card>
        </div>
    )
}